<template lang="html">
  <div class="ActivityMembersPage">
    <div v-if="isClient">
      <SearchBar class="SearchBarV2" :placeholder="$t('find_by_name_or_tags')" :autocompleteListItems="tags"
        v-on:item-selected="handleItemSelected($event)" />
    </div>
    <div class="ActivityMembersPage--MembersTags" v-if="isClient">
      <Tag :name="t" class="TagV2" v-for="(t,i) in filterTags" isClickable isActive v-on:remove="removeFilterTag(i)" />
    </div>
    <div class="ActivityMembersPage--MembersInfo" v-if="isClient">
      <p>{{$t('members_show_of',{membersNum:members.length,membersTotal:totalMembers})}}</p>
      <hr>
      <p v-if="notParticipateMembers>0">{{$t('members_not_participated',{members:notParticipateMembers})}}</p>
      <div v-if="isClient">
        <CommunityButton v-on:click="remindAllMembers()"
          v-if="isModerator && notParticipateMembers>0 && communityInfo.status != 'REVISION' && communityCurrentActivity.status != 'FINISHED'">
          {{$t('members_remind')}}({{ notParticipateMembers }})</CommunityButton>
      </div>
      <div v-if="isClient">
        <CommunityButton v-on:click="openReminderDates()"
          v-if="isModerator && notParticipateMembers>0 && communityInfo.status != 'REVISION' && communityCurrentActivity.status != 'FINISHED'">
          {{ reminderTraductions }}</CommunityButton>
      </div>
      <p v-if="notParticipateMembers==0">{{$t('members_all_participated')}}</p>

    </div>
    <p class="TotalMembers">{{$t('participants_show_total',{membersTotal:communityCurrentActivity.membersNum})}}</p><br>
    <div class="ActivityMembersPage--MembersList">
      <CommunityUser :allowPublicTags="true"
        :hideGamification="communityInfo.hideGamification || (isUser && communityInfo.privateLabs)" :member="m"
        v-for="(m,i) in members" :allowRemind="isModerator" :allowTags="isModerator"
        v-on:remind-member="remindMember" />
    </div>
  </div>
  <Teleport to="body">
    <div class="modal-overlay" v-if="showDatePicker" @click.self="closeReminderDates">
      <div class="modal-content">
        <div v-if="isLoading" class="loader-overlay">
          <div class="loader"></div>
        </div>
        <div v-else>
          <div class="modal-header">
            <span @click="closeReminderDates" class="close-button">&times;</span>
          </div>

          <div class="modal-title">
            <h3>{{ $t('members_remind_programed') }}</h3>
          </div>

          <div class="modal-body">
            <DatePicker ref="datepicker" :multi-dates="true" :multi-dates-limit="2" v-model="reminderDates"
              :placeholder="$t('please_select_date')" @update:model-value="handleDate" @cleared="clearReminderDates"
              :teleport="true" :min-date="latestDate" :max-date="dateToLast" />
          </div>

          <div class=" modal-footer">
            <CommunityButton :color="'#ccc'" :textColor="'black'" @click="closeReminderDates"> {{ $t('action_cancel') }}
            </CommunityButton>
          </div>
        </div>
      </div>
    </div>
  </Teleport>
</template>

<script>
import Swal from 'sweetalert2'
import { mapGetters, mapMutations } from 'vuex';
import store from '@/store';
import Tag from '~/components/Tag';
import CommunityButton from '@/components/CommunityButton.vue'
import SearchBar from '~/components/SearchBar/SearchBar.vue';
import CommunityUser from '@/components/CommunityUser'
import {
  COMMUNITY_REMIND_CURRENT_ACTIVITY_PARTICIPANT,
  COMMUNITY_FETCH_CURRENT_ACTIVITY_PARTICIPANTS,
  COMMUNITY_FETCH_CURRENT_ACTIVITY_PARTICIPANTS_EL,
  COMMUNITY_REMIND_CURRENT_ACTIVITY_ALL_PENDING_PARTICIPANTS,
  COMMUNITY_PROGRAM_REMIND_CURRENT_ACTIVITY_PARTICIPANT
} from '@/store/community/activities.module';
import DatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';
export default {
  name: 'ActivityMembersPage',
  props:{
    tags:Array
  },
  components: {
    Tag,
    CommunityButton,
    SearchBar,
    CommunityUser,
    DatePicker
  },
  computed: {
    ...mapGetters([
      'communityInfo',
      'isClient',
      'isModerator',
      'isUser',
      'communityCurrentActivity',
      'communityPublicTags',
      'communityTags',
      'onlineUsers'
    ]),
    latestDate() {
    const today = new Date();
    const dateFrom = new Date(this.communityCurrentActivity.dateFrom);
    return today > dateFrom ? today : dateFrom;
  },
    dateToLast() {
      if (!this.communityCurrentActivity?.dateTo) return null;

      const dateTo = new Date(this.communityCurrentActivity.dateTo);

      dateTo.setHours(23, 59, 59, 0);

      return dateTo;
    },
     isNotEmptyReminder(){
       return this.reminderDates && this.reminderDates.length > 0
    },
    reminderTraductions(){
      return this.isNotEmptyReminder ? this.$t('members_edit_reminder_date') : this.$t('members_remind_programed')
    }
  },
  data(){
    return{
      members:[],
      notParticipateMembersTemp:[],
      filterNickname: "",
      filterTags:[],
      notParticipateMembers: 0,
      totalMembers: 0,
      page:0,
      pageSize:50,
      backupFilter:[],
      isLoadingMembers: false,
      currentRequest: null,
      debounceTimeout: null,
      showDatePicker:false,
      reminderDates:null,
      isLoading: false,
      reminderDatesCleared:null,
      
    }
  },
  methods:{
    async remindAllMembers(){
      await store.dispatch(COMMUNITY_REMIND_CURRENT_ACTIVITY_ALL_PENDING_PARTICIPANTS, {
        activityId: this.$route.params.id
      }).then((data) => {
        Swal.fire(this.$t('msg_members_remind_sent',{members:data.length}))
      });
    },
    openReminderDates(){
      this.showDatePicker = true
    },
    closeReminderDates(){
      this.showDatePicker = false
      this.reminderDatesCleared = false
    },
    clearReminderDates(){
      this.reminderDatesCleared = true
    },
    async handleDate(date){
      this.reminderDates = date || [];
      this.isLoading = true; 
      await this.$nextTick(this.programReminderForAllMembers);
      this.isLoading = false;
      this.$nextTick(this.closeReminderDates())
    },
    async programReminderForAllMembers(){

        const [data, error] = await store.dispatch(COMMUNITY_PROGRAM_REMIND_CURRENT_ACTIVITY_PARTICIPANT, {
          activityId: this.$route.params.id,
          reminderDates: this.reminderDates
        });

        if (data) {
          console.log(data.message)
          Swal.fire(this.reminderDatesCleared ? this.$t('community_config_success') : this.$t('msg_members_remind_sent_programed', { members: data.testerNum }));
        } else if (error) {
          console.error(error);
        }
    
      // await store.dispatch(COMMUNITY_PROGRAM_REMIND_CURRENT_ACTIVITY_PARTICIPANT, {
      //   activityId: this.$route.params.id,
      //   reminderDates: this.reminderDates
      // }).then((data) => {
      //   Swal.fire(this.$t('msg_members_remind_sent',{members:data.length}))
      // });
    },
    async remindMember(member,type){
      await store.dispatch(COMMUNITY_PROGRAM_REMIND_CURRENT_ACTIVITY_PARTICIPANT, {
        activityId: this.$route.params.id,
        memberId:member.identifier,
      })
      if (type != "ALL") {
        Swal.fire(this.$t('msg_members_remind_sent_member',{member:member.nickname}))
      }
    },
    handleItemSelected(event) {
      clearTimeout(this.debounceTimeout);
      this.debounceTimeout = setTimeout(() => {
        this.filterActivityMembers(event);
      }, 500);
    },
    async filterActivityMembers(filter, isPageScroll){
      if (filter == undefined || filter.text == "") {
        this.filterNickname = "";
        this.email="";
      }
      if (filter != undefined && filter.type == 'filter') {
        if (filter.text != "") {
          this.filterTags.push(filter.text)
        }
        this.filterNickname = ""
        this.email="";
      }
      let publicTags = [];
      let tags = [];
      for (let t of this.communityTags) {
        if (this.filterTags.includes(t)) tags.push(t);
      }
      for (let t of this.communityPublicTags) {
        if (this.filterTags.includes(t)) publicTags.push(t);
      }
      if (filter != undefined && filter.type == "nickname"){
         this.filterNickname = filter.text;
         this.email="";
      }
      if (filter != undefined && filter.type == "email"){
        this.email = filter.text;
        this.filterNickname = "";
      }
      if(!isPageScroll && this.page > 0){
        this.pageSize = (this.page + 1) * this.pageSize
        this.page = 0
      }
      if (filter != undefined && filter.type != "filter") {
        this.backupFilter = filter;
      } else {
        this.backupFilter = null;
      }
      this.isLoadingMembers = true

      const request = store.dispatch(this.isClient ? COMMUNITY_FETCH_CURRENT_ACTIVITY_PARTICIPANTS_EL : COMMUNITY_FETCH_CURRENT_ACTIVITY_PARTICIPANTS, {
        order: "recent",
        activityId: this.$route.params.id,
        filter:tags.join(','),
        publicFilter:publicTags.join(','),
        nickname:this.filterNickname != "" ? this.filterNickname : undefined,
        page:this.page,
        pageSize:this.pageSize
      });

      this.currentRequest = request;
      const data = await request;
      if (this.currentRequest !== request) return;

      this.isLoadingMembers = false
      if(isPageScroll) this.members = this.members.concat(data.communityMembers)
      else this.members = data.communityMembers;
      for(let m of this.members){
        m.online = this.onlineUsers.testersOnlineCondensed.includes(m.identifier)
      }
      this.totalMembers = data.totalTesters
      this.setNotParticipated()
    },
    setNotParticipated(){
      this.notParticipateMembersTemp = this.members.filter(function(item){
        if (!item.hastParticipated) {
          return item
        }
      });
      this.notParticipateMembers = (this.communityCurrentActivity.membersNum - this.communityCurrentActivity.participatedTesterNum);
    },
    removeFilterTag(i){
      this.filterTags.splice(i,1)
      this.filterActivityMembers()
    },
    pageScroll(scrollTop, scrollHeight, clientHeight){
      if(this.isLoadingMembers) return
      if (scrollTop + clientHeight >= scrollHeight - 5){
        if(this.page == 0 && this.pageSize > 10){
          this.page = this.pageSize / 10
          this.pageSize = 10
        }
        else this.page++
        this.filterActivityMembers(this.backupFilter, true)
      }
    },
    addFutureReminderDates() {
      const today = new Date();
      if (this.communityCurrentActivity?.reminderDates) {
        this.reminderDates = this.communityCurrentActivity.reminderDates
          .filter(element => element.status === "NEW" && new Date(element.date) > today)
          .map(element => element.date);
      }
    }
  },
  async mounted(){
    await this.filterActivityMembers()
    this.addFutureReminderDates();
  }

}
</script>
<style lang="scss" scoped>
.ActivityMembersPage{
  .TotalMembers {
    color: #747474;
  }
  &--MembersTags{
    margin-top: 10px;
  }
  &--MembersInfo{
    display: flex;
    align-items: center;
    margin: 10px 0;
    hr{
      margin: 0 10px;
      width: 1px;
      height: 15px;
    }
    button{
      background: var(--primary-color);
      font-size: 14px;
      margin-left: 20px;
    }
  }
  &--MembersList{
    display: grid;
    grid-template-columns: 50% 50%;
    grid-gap: 5px;
    @media only screen and (max-width: 600px) {
      grid-template-columns: 100%;
    
  }
  }
}
</style>

<style lang="scss" scoped>
.modal-overlay {
  position: fixed;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  transition: opacity 0.3s ease;
}


.modal-content {
  position: relative;
  background: #fff;
  padding: 20px 30px;
  border-radius: 12px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  width: min(90%, 500px);
  max-width: 500px;
  animation: modalFadeIn 0.3s ease;
  transform: translateY(-10px);
}

.modal-header {
  position: absolute;
  top: 10px;
  right: 15px;
}

.close-button {
  font-size: 24px;
  color: #333;
  background: none;
  border: none;
  cursor: pointer;
  transition: color 0.3s ease;
}

.close-button:hover {
  color: #666;
}

.modal-title {
  text-align: center;
  margin-top: 30px;
}

.modal-title h3 {
  margin: 0;
  color: #333;
  font-size: 1.5em;
}

.modal-body {
  margin-top: 20px;
  display: flex;
  justify-content: center;
}

.modal-footer {
  margin-top: 30px;
  display: flex;
  justify-content: center;
}

.loader-overlay {
  background:transparent;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.loader {
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-top: 4px solid #333;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

/* Animación de entrada */
@keyframes modalFadeIn {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}
</style>